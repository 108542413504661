<template>
  <div>
    {{ dataObj }}
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userInfo } from "@/api/user";

export default {
  mixins: [mainCommon],
  data() {
    return {
      dataObj: {},
    };
  },
  created() {
    this.HandleGetInfo();
  },
  methods: {
    HandleGetInfo() {
      userInfo(this.$route.params.username).then((res) => {
        this.dataObj = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 659px) {
}
</style>